//ADD TO CART SECTION
const header = document.getElementById('headroom');
//Click on + - button near quantity
document.querySelectorAll('.js-quantity-toggle').forEach(element => {
    element.addEventListener('click', function (e) {
        setQuantity(this);
    });
});

//Function + - button near quantity
function setQuantity(element) {
    let quantityInput = element.closest('div').children[1];
    if(form) {
        var quantityInputQuantity = document.getElementById('quantity');
    }
    let currentVal = parseInt(quantityInput.value);
    if (!isNaN(currentVal)) {
        if (element.dataset.type === 'add') {
            currentVal++;
        } else {
            if (currentVal <= 1) {
                currentVal = 1;
            } else {
                currentVal--;
            }
        }
        //Change value of button quantity input
        quantityInput.value = currentVal;
        //Change value of button quantity input in hidden form
        if(quantityInputQuantity) {
            quantityInputQuantity.value = currentVal;
        }
    } else {
        //Change value of button quantity input
        quantityInput.value = 1;
        //Change value of button quantity input in hidden form
        if(quantityInputQuantity) {
            quantityInputQuantity.value = 1;
        }
    }
}

function addToBucket(number) {
    //Set currentQuantity number to header cart icon (desktop and mobile - two elements)
    //Toto bude treba este prerobit, aby to niekam ukladalo do session, lebo aktualne sa to iba prida ako innerHTML
    const cartCounters = document.querySelectorAll(".cart-counter");
    cartCounters.forEach(cartCounter => {
        let currentCounterNumber = parseInt(cartCounter.innerHTML);
        cartCounter.classList.add('active');
        cartCounter.innerHTML = currentCounterNumber + number;
        cartCounter.classList.remove('active');
    });
}

//Click on addToCart button
let preventMoreClick = false;
document.querySelectorAll('.js-addToCart').forEach(element => {
    element.addEventListener('click', function (e) {
        e.preventDefault();

        if (preventMoreClick === false) {
            preventMoreClick = true;
            let currentElement = this;

            let productPattern = this.dataset.product;
            let orderGrid = this.dataset.grid;
            let form = document.getElementById('add-product-form-' + productPattern + '-' + orderGrid);
            const code = e.target.dataset.code;
            if(code) {
                document.getElementById('item').value = code;
            }
            if(form) {
                //Set quantity from FORM input
                const currentQuantity = parseInt(document.getElementById('quantity-'+productPattern + '-' + orderGrid).value);
                //If button notification is showing -> hide
                if(currentElement.classList.contains('added-to-cart')) {
                    currentElement.classList.remove('added-to-cart');
                }
                //Set loader to button + show page header
                currentElement.classList.add('active');
                if (header.classList.contains("headroom--unpinned")) {
                    header.classList.remove('headroom--unpinned');
                    header.classList.add('headroom--pinned');
                }
                let XHR = new XMLHttpRequest();
                let formData = new FormData(form);
                XHR.onload = function() {
                    if (XHR.status === 200) {
                        // Tu pride z BE odpoved na API
                        const res = JSON.parse(XHR.response);
                        //Set message and class to notification near button
                        // const append_class = res.status ? "text-default" : "text-danger";
                        const request_status = res.status ? "succes" : "error";
                        // notification.classList.add(append_class);
                        // notification.innerHTML = res.msg;
                        notify(request_status, res.msg);
                        if(res.status) {
                            addToBucket(currentQuantity);
                        }
                    }
                    //Remove loader from current clicked addToCart button and show notification
                    currentElement.classList.remove('active');
                    currentElement.classList.add('added-to-cart');
                    //Remove prevent more click from current clicked addToCart button
                    preventMoreClick = false;
                };
                XHR.onerror = function() {
                    // notification.innerHTML = 'Oops! Form not found.';
                    //Remove loader from current clicked addToCart button and show notification
                    currentElement.classList.remove('active');
                    currentElement.classList.add('added-to-cart');
                    notify('error', 'Oops! Vyskytol sa problém.');
                    //Remove prevent more click from current clicked addToCart button
                    preventMoreClick = false;
                };
                XHR.open("POST", form.action);
                XHR.send(formData);
            }
        }
    });
});

